<template>
  <div class="game-container">
    <RocketReviewGame :bcgImg="bcgImg" :durationArr="durationArr" :audioUrl="audioUrl" :trigger="`button`" />
  </div>
</template>

<script>
import RocketReviewGame from "@/components/Course/GamePage/RocketReviewGame";
export default {
  data() {
    return {
      bcgImg: require("@/assets/img/16-GAMES/G09-submarine/submarine-background-lesson-32.svg"),
      durationArr: [
        0.945,
        0.865,
        0.775,
        0.695,
        0.615,
        0.535,
        0.455,
        0.37,
        0.29,
        0.205,
        0.125,
        0.051
      ],
      audioUrl: require("@/assets/audio/chapter_1/lesson_8/submarine-propeller.mp3")
    };
  },
  components: {
    RocketReviewGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
