<template>
  <div class="game-container">
    <ReviewTrain
      :step="1"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
     
     vocabularyReviewList: [
          [
          {
            pinyin: "zhè",
            hanzi: "这",
          },
          {
            pinyin: "nà",
            hanzi: "那",
          },
          {
            pinyin: "kàn",
            hanzi: "看",
          },
          {
            pinyin: "māo",
            hanzi: "猫",
          },
          
        ],
        [
          {
            pinyin: "bù",
            hanzi: "不",
          },
          {
            pinyin: "de",
            hanzi: "的",
          },
          {
            pinyin: "gǒu",
            hanzi: "狗",
          },
          {
            pinyin: "yú",
            hanzi: "鱼",
          },
          {
            pinyin: "niǎo",
            hanzi: "鸟",
          },
        ],
        [
          {
            pinyin: "zài",
            hanzi: "在",
          },
          {
            pinyin: "zhèr",
            hanzi: "这",
          },
          {
            pinyin: "er",
            hanzi: "儿",
          },
          {
            pinyin: "nàr",
            hanzi: "那儿",
          },
          {
            pinyin: "nǎr",
            hanzi: "哪儿",
          },
        ],
      ],
      knowledgeReviewList: [
       [
          {
            pinyin: "zhè/nà shì……",
            hanzi: "这/那是……",
          },
          {
            pinyin: "de",
            hanzi: "的",
          },
          {
            pinyin: "bù",
            hanzi: "不",
          },
        ],
      
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "g",
          },
          {
            vocabulary: "k",
          },
          {
            vocabulary: "h",
          },
          
          
        ],
        [
          {
            vocabulary: "j",
          },
          {
            vocabulary: "q",
          },
          {
            vocabulary: "x",
          },
          
        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/28-shuzhezhegou-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/30-hengpiewangou-white.svg"),
          },
        ],
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-26/26-hengzhezhepie-white.svg"),
          },
         
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>