<template>
  <div class="game-container">
    <WriteHanziReviewPage
      :LeftImgList="LeftImgList"
      :hanziInfoList="hanziInfoList"
      :gifList="gifList"
    />
  </div>
</template>

<script>
import WriteHanziReviewPage from "@/components/Course/CoursePage/WriteHanziReviewPage";
export default {
  data() {
    return {
      hanziInfoList: [
        {
          pinyin: "zài",
          tipsList: ["我", "在", "中", "国", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-30/hanzi-zai-grey-border.svg"),
          stepNumber: 6,
          tipsIndex: 1,
        },
        {
          pinyin: "bú",
          tipsList: ["我", "不", "是", "老", "师", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-28/hanzi-bu-grey-border.svg"),
          stepNumber: 4,
          tipsIndex: 1,
        },
        {
          pinyin: "niǎo",
          tipsList: ["那", "是", "鸟", "的", "家", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-28/hanzi-niao-grey-border.svg"),
          stepNumber: 5,
          tipsIndex: 2,
        },
      ],
    };
  },
  computed: {
    LeftImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-32/image-${i}.svg`)
        );
      }
      return imgArr;
    },
    gifList() {
      let imgArrRen = [];
      for (let i = 1; i <= 6; i++) {
        imgArrRen.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-30/lesson-30-zai-${i}.gif`)
        );
      }
      let imgArrYou = [];
      for (let i = 1; i <= 4; i++) {
        imgArrYou.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-28/lesson-28-bu-${i}.gif`)
        );
      }
      let imgArrMa = [];
      for (let i = 1; i <= 5; i++) {
        imgArrMa.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-28/lesson-28-niao-${i}.gif`)
        );
      }
      return [imgArrRen, imgArrYou, imgArrMa];
    },
  },
  components: {
    WriteHanziReviewPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











