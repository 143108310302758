var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("RocketReviewGame", {
        attrs: {
          bcgImg: _vm.bcgImg,
          durationArr: _vm.durationArr,
          audioUrl: _vm.audioUrl,
          trigger: `button`,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }