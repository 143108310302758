var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("ReviewTrain", {
        attrs: {
          step: 3,
          vocabularyReviewList: _vm.vocabularyReviewList,
          knowledgeReviewList: _vm.knowledgeReviewList,
          pinyinWordReviewList: _vm.pinyinWordReviewList,
          strokesReviewList: _vm.strokesReviewList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }