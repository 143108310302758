<template>
  <div class="game-container">
    <ReviewTrain
      :step="0"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
     
     vocabularyReviewList: [
          [
          {
            pinyin: "tā",
            hanzi: "他",
          },
          {
            pinyin: "tā",
            hanzi: "她",
          },
          {
            pinyin: "shéi",
            hanzi: "谁",
          },
          {
            pinyin: "jiào",
            hanzi: "叫",
          },
          
        ],
        [
          
          {
            pinyin: "shénme",
            hanzi: "什么",
          },
          {
            pinyin: "jǐ",
            hanzi: "几",
          },
          {
            pinyin: "suì",
            hanzi: "岁",
          },
          {
            pinyin: "duō dà",
            hanzi: "多大",
          },
        ],
        [
          {
            pinyin: "Zhōngguó rén",
            hanzi: "中国人",
          },
          {
            pinyin: "yě",
            hanzi: "也",
          },
         {
            pinyin: "nǎ",
            hanzi: "哪",
          },
          {
            pinyin: "guó",
            hanzi: "国",
          },
        ],
      ],
      knowledgeReviewList: [
       [
          {
            pinyin: "zhè/nà shì……",
            hanzi: "这/那是……",
          },
          {
            pinyin: "de",
            hanzi: "的",
          },
          {
            pinyin: "bù",
            hanzi: "不",
          },
        ],
      
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "g",
          },
          {
            vocabulary: "k",
          },
          {
            vocabulary: "h",
          },
          
          
        ],
        [
          {
            vocabulary: "j",
          },
          {
            vocabulary: "q",
          },
          {
            vocabulary: "x",
          },
          
        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/28-shuzhezhegou-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/30-hengpiewangou-white.svg"),
          },
        ],
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-26/26-hengzhezhepie-white.svg"),
          },
         
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>