<template>
  <!-- layout-container lesson 16 -->
  <div class="lesson32-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageB />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 全局白板部分 -->
      <WhiteBoard />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageB from "./3_SyllabusPage_B.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import ReviewOne from "./4_ReviewOne.vue";
import RacketGame from "./5_RocketReviewGame";
import FindElementByPicGame from "./6_FindElementByPicGame";
import BingoGame from "./7_BingoGame.vue";
import ReviewTwo from "./8_ReviewTwo.vue";
import AppleTreeGame from "./9_AppleTreeGame.vue";
import PracticeOne from "./10_PracticeOne.vue";
import SpeakingGame from "./11_SpeakingGame.vue";
import WordsCardPageOne from "./12_WordsCardPage.vue";
import ReviewThree from "./13_ReviewThree.vue";
import ListenThenChooseWords from "./14_ListenThenChooseWords.vue";
import FrogJumpGame from "./15_FrogJumpGame.vue";
import ReviewFour from "./16_ReviewFour.vue";
import WriteStrokeReview from "./17_WriteStrokeReview.vue";
import FindStrokeReviewGame from "./18_FindStrokeReviewGame.vue";
import DropsGame from "./19_DropsGame.vue";
import WriteHanziReview from "./20_WriteHanziReview.vue";
import ReviewFive from "./21_ReviewFive.vue";
import WordsCardPageTwo from "./22_WordsCardPage.vue";
import RankMapPageTwo from "./23_RankMapPageTwo.vue";
import ByePage from "./24_ByePage.vue";
export default {
  name: "Lesson32",
  components: {
    SyllabusPageB,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    ReviewOne,
    RacketGame,
    FindElementByPicGame,
    BingoGame,
    ReviewTwo,
    AppleTreeGame,
    PracticeOne,
    SpeakingGame,
    WordsCardPageOne,
    ReviewThree,
    ListenThenChooseWords,
    FrogJumpGame,
    ReviewFour,
    WriteStrokeReview,
    FindStrokeReviewGame,
    DropsGame,
    WriteHanziReview,
    ReviewFive,
    WordsCardPageTwo,
    RankMapPageTwo,
    ByePage,
  },
  mixins: [lessonMixin],
  data() {
    return {
      pageNum: 1,
      // lesson2 中的所有page
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        ReviewOne,
        RacketGame,
        FindElementByPicGame,
        BingoGame,
        ReviewTwo,
        AppleTreeGame,
        PracticeOne,
        SpeakingGame,
        WordsCardPageOne,
        ReviewThree,
        ListenThenChooseWords,
        FrogJumpGame,
        ReviewFour,
        WriteStrokeReview,
        FindStrokeReviewGame,
        DropsGame,
        WriteHanziReview,
        ReviewFive,
        WordsCardPageTwo,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson32-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
