<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "māo",
          hanzi: "猫",
          english: "cat",
          image: require(`@/assets/img/12-Vocabulary/lesson-25-mao-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "gǒu",
          hanzi: "狗",
          english: "dog",
          image: require(`@/assets/img/12-Vocabulary/lesson-27-gou-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "yú",
          hanzi: "鱼",
          english: "fish",
          image: require(`@/assets/img/12-Vocabulary/lesson-27-yu-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "niǎo",
          hanzi: "鸟",
          english: "bird",
          image: require(`@/assets/img/12-Vocabulary/lesson-27-niao-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "zhè",
          hanzi: "这",
          english: "this",
          image: require(`@/assets/img/12-Vocabulary/lesson-25-zhe-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "six",
          isShowImg: "true",
          currentNum: 6,
          pinyin: "nà",
          hanzi: "那",
          english: "that",
          image: require(`@/assets/img/12-Vocabulary/lesson-25-na-bubble.svg`),
        },
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
