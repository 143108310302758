<template>
  <div class="game-container">
    <FindElementByPicReviewGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicReviewGame from "@/components/Course/GamePage/FindElementByPicReviewGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [

        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-27-gou.svg"),
        },
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-25-kan.svg"),
        },
        {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-27-yu.svg"),
        },
        {
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-25-mao.svg"),
        },
        {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-27-niao.svg"),
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-27-bu.svg"),
        },           
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "猫",
            pinyin: "māo",
            sound: require("@/assets/audio/L1/5-Words/mao1.mp3")
          },
          {
            id: 2,
            element: "狗",
            pinyin: "gǒu",
            sound: require("@/assets/audio/L1/5-Words/gou3.mp3")
          },
          {
            id: 3,
            element: "鸟",
            pinyin: "niǎo",
            sound: require("@/assets/audio/L1/5-Words/niao3.mp3")
          }
        ],
        rightList: [
          {
            id: 4,
            element: "鱼",
            pinyin: "yú",
            sound: require("@/assets/audio/L1/5-Words/yu2.mp3")
          },
          {
            id: 5,
            element: "不",
            pinyin: "bù",
            sound: require("@/assets/audio/L1/5-Words/bu4.mp3")
          },
          {
            id: 6,
            element: "看",
            pinyin: "kàn",
            sound: require("@/assets/audio/L1/5-Words/kan4.mp3")
          }
        ]
      }
    };
  },
  components: {
    FindElementByPicReviewGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
