<template>
  <div class="game-container">
    <RainDropGame
      :optionsList="optionsList"
      :answerList="answerList"
      :bgImage="bgImage"
      @changeAnswerStatus="changeAnswerStatus"
      :starNum="3"
    />
  </div>
</template>

<script>
import RainDropGame from "@/components/Course/GamePage/RainDropGame";
export default {
  data() {
    return {
      bgImage: require("@/assets/img/16-GAMES/G53-drops/background.svg"),
      optionsList: [
        [
          {
            index: 1,
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-32/drop-kan.svg"),
            isAnswer: true,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-kou.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ren.svg"),
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-32/drop-er.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-32/drop-er2.svg"),
            isAnswer: true,
            index: 2,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-32/drop-ji.svg"),
            
          },
          
          
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-da.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-shei.svg"),
            
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-zhe.svg"),
            isAnswer: true,
            index: 3,
          },
        ],
      ],

      answerList: [
        [
          
         
          {
            hanzi: "你",
            pinying: "nǐ",
            isChooseCorrect: true,
          },
           {
            id: 1,
            hanzi: "看",
            pinying: "kàn",
            isChooseCorrect: false,
          },
        ],
        [
          {
            hanzi: "这",
            pinying: "zhè",
            isChooseCorrect: true,
          },
          {
            id: 2,
            hanzi: "儿",
            pinying: "èr",
            isChooseCorrect: false,
          },
        
        ],
        [
           {
            id:3,
            hanzi: "这",
            pinying: "zhè",
            isChooseCorrect: false,
          },
          {
            hanzi: "个 ",
            pinying: "gè",
            isChooseCorrect: true,
          },
         
        ],
      ],
    };
  },
  components: {
    RainDropGame,
  },
  methods: {
    changeAnswerStatus(item, index) {
      this.answerList[index].forEach((ele) => {
        if (ele.id === item.index) {
          ele.isChooseCorrect = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











