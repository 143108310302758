<template>
  <div class="game-container">
    <PinyinMoveGame
      :bgImg="bgImg"
      :studentImg="studentImg"
      :lessonNum="lessonNum"
      :durationArr="durationArr"
      :textToShow="textToShow"
    />
  </div>
</template>

<script>
import PinyinMoveGame from "@/components/Course/GamePage/PinyinMoveGame";
export default {
  data() {
    return {
      lessonNum: 320,
      bgImg: require("@/assets/img/16-GAMES/G09-rocket-horizontal/lesson-32-map-galaxy.svg"),
      // frontImg: require("@/assets/img/16-GAMES/G12-map/lesson-07-map-2.svg"),
      studentImg: require("@/assets/img/16-GAMES/G09-rocket-horizontal/spaceship-transgalaxy-cat.svg"),
      durationArr: [0.1, 0.28, 0.45, 0.65],
      textToShow: [{},{'pinyin': 'Nà shì niǎo.', 'hanzi' : '那是鸟。'},{'pinyin': 'Zhè shì māo.', 'hanzi' : '这是猫。'},{'pinyin': 'Nà shì yú.', 'hanzi' : '那是鱼。'},{'pinyin': 'Zhè shì gǒu.', 'hanzi' : '这是狗。'}]
    };
  },
  components: {
    PinyinMoveGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}


/deep/ .student-img .object-wrapper > img {
  position: relative;
  z-index: 999;
}

/deep/ .flame {
  bottom: -5% !important;
  left: -40% !important;
  position: absolute;

  width: 64%;
  height: 90%;
  // background: linear-gradient(-45deg, red, orange);
  background: transparent;
  border-radius: 15px 15px 0px 15px;
  transform: rotate(166deg);
  // animation: 0.1s flame infinite;
  filter: blur(10px);
  box-shadow: 17px 20px 90px #700;
  // border:45px solid black;
  border-left-width: 25px;
  border-top-width: 25px;
  transform: rotate(69deg) scale(0.5);
  opacity: 0;
  transition: opacity 0.5s;
  &:after,
  &:before {
    content: "";
    width: 20%;
    height: 20%;
    display: block;
    position: absolute;
    background: linear-gradient(92deg, red, orange);
    animation: 0.2s flame infinite;
    transform: scale(0.8) rotate(20deg);
    border-radius: 10px 10px 0px 10px;
    top: 20px;
  }
  &:after {
    background: linear-gradient(299deg, red, orange) !important;
  }
  &:before {
    // top: 0;
    animation-duration: 0.09s;
    transform: scale(0.9) rotate(-220deg) translate(10px, 0px);
  }
  &.flameIsVisible {
    opacity: 1;
  }
}

@keyframes flame {
  0% {
    // height: 75px;
    // height: 75px;
    width: 100%;
    width: 100%;
  }
  50% {
    height: 99%;
    width: 99%;
  }
  100% {
    height: 100%;
    width: 100%;
  }
}
</style>
