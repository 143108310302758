var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "darg-to-dress-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c("Star", {
        attrs: {
          totalStarNumber: _vm.bgImgList.length,
          currentIndex: _vm.starNo,
        },
      }),
      _c(
        "div",
        { staticClass: "game-content", class: "lesson-" + _vm.lessonNO },
        [
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(_vm._s(_vm.titleInfo.hanzi)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.isShowSentence
            ? _c(
                "div",
                { staticClass: "sentence-area" },
                _vm._l(_vm.bgImgList, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentIndex === index,
                          expression: "currentIndex === index",
                        },
                      ],
                      key: index + "sentence",
                      staticClass: "sentence",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title-pinyin pinyin font-pinyin-medium",
                        },
                        [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "title-hanzi font-hanzi-medium" },
                        [_vm._v(_vm._s(item.hanzi))]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "background-area" },
            [
              _c(
                "transition",
                { attrs: { name: "el-fade-in" } },
                [
                  _vm._l(_vm.bgImgList, function (item, index) {
                    return [
                      index === _vm.currentIndex
                        ? _c("img", {
                            key: index + "bg",
                            staticClass: "bg-img-item",
                            attrs: {
                              src: item.isDone ? item.bgImgDone : item.bgImg,
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._l(_vm.bgImgList, function (item, index) {
            return _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: index === _vm.currentIndex,
                  expression: "index === currentIndex",
                },
              ],
              key: index + "drop",
              staticClass: "drop-area",
              class: "",
              attrs: { draggable: "true" },
              on: {
                drop: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleDropImg($event, item.id)
                },
                dragover: function ($event) {
                  $event.stopPropagation()
                  return _vm.allowDrop($event)
                },
              },
            })
          }),
          _c(
            "div",
            { staticClass: "drag-area" },
            _vm._l(_vm.optionImgList, function (item, index) {
              return _c(
                "div",
                {
                  key: index + "drag",
                  staticClass: "option-item",
                  class: "option-" + item.id,
                },
                [
                  _c("img", {
                    class: { notShow: _vm.dragId === item.id },
                    attrs: { src: item.image, draggable: "true" },
                    on: {
                      dragstart: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleDragImg($event, item.id)
                      },
                      drop: function ($event) {
                        $event.stopPropagation()
                        return _vm.Sdrop($event)
                      },
                      dragend: function ($event) {
                        $event.stopPropagation()
                        return _vm.dragend()
                      },
                    },
                  }),
                ]
              )
            }),
            0
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }