<template>
  <div class="darg-to-dress-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <Star :totalStarNumber="bgImgList.length" :currentIndex="starNo" />
    <div class="game-content" :class="'lesson-' + lessonNO">
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">{{ titleInfo.hanzi }}</div>
        </div>
      </div>

      <div class="sentence-area" v-if="isShowSentence">
        <div
          class="sentence"
          v-for="(item, index) in bgImgList"
          :key="index + 'sentence'"
          v-show="currentIndex === index"
        >
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ item.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">{{ item.hanzi }}</div>
        </div>
      </div>

      <div class="background-area">
        <transition name="el-fade-in">
          <template v-for="(item, index) in bgImgList">
            <img
              :src="item.isDone ? item.bgImgDone : item.bgImg"
              class="bg-img-item"
              :key="index + 'bg'"
              v-if="index === currentIndex"
            />
          </template>
        </transition>
      </div>
      <div
        class="drop-area"
        v-for="(item, index) in bgImgList"
        :class="''"
        :key="index + 'drop'"
        v-show="index === currentIndex"
        draggable="true"
        @drop.stop="handleDropImg($event, item.id)"
        @dragover.stop="allowDrop($event)"
      ></div>

      <div class="drag-area">
        <div
          v-for="(item, index) in optionImgList"
          class="option-item"
          :class="'option-' + item.id"
          :key="index + 'drag'"
        >
          <img
            :class="{ notShow: dragId === item.id }"
            :src="item.image"
            draggable="true"
            @dragstart.stop="handleDragImg($event, item.id)"
            @drop.stop="Sdrop($event)"
            @dragend.stop="dragend()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "@/components/Course/GamePage/Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "DragToShowSentenceGame",
  props: {
    optionImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImg: {
      type: String,
      require: true,
    },
    lessonNO: {
      type: Number,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  components: {
    PageButton,
    Star,
  },
  data() {
    return {
      isLastStep: false,
      showHnaziIndex: false,
      dragId: null,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      dropImgSocketInfo: {},
      dragImgSocketInfo: {},
      currentIndex: 0,
      starNo: 0,
      isShowSentence: false,
    };
  },
  watch: {
    dropImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005701,
          data: { value },
          text: "DragToGressGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005702,
          data: { value },
          text: "DragToGressGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dropImgEvent", ({ id }) => {
      this.handleDropImg("socket", id, true);
    });
    this.$bus.$on("dragImgEvent", ({ id }) => {
      this.handleDragImg("socket", id, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("dropImgEvent");
    this.$bus.$off("dragImgEvent");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleDragImg(event, id, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragImgSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      console.log(id);
      this.dragId = id;
      if (this.dragId === id) {
        console.log("拖拽中");
      }
    },
    handleDropImg(event, id, isFromSocket = false) {
      console.log("拖拽成功");
      if (!isFromSocket) {
        this.dropImgSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      console.log(this.dragId);
      if (this.dragId === id) {
        this.$emit("showDragImg", this.currentIndex);
        playCorrectSound(true, false);
        this.isShowSentence = true;
        setTimeout(() => {
          this.currentIndex++;
          this.starNo++;
          this.isShowSentence = false;
          if (this.currentIndex === this.bgImgList.length) {
            playCorrectSound();
            this.isLastStep = true;
            startConfetti();
            this.currentIndex = this.bgImgList.length - 1;
          }
        }, 1500);
      } else {
        playCorrectSound(false, false);
      }
    },
    Sdrop: function (event) {
      event.preventDefault();
    },
    allowDrop: function (event) {
      event.preventDefault();
    },
    dragend() {
      this.dragId = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.option-List {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.title-area {
  z-index: 9;
  position: absolute;
  top: 10%;
  width: 30%;
  height: 18%;
  background: #224e96;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  .title {
    .title-pinyin,
    .title-hanzi {
      text-align: center;
    }
  }
}
.sentence-area {
  z-index: 9;
  position: absolute;
  left: 25%;
  bottom: 3%;
  width: 50%;
  height: 16%;
  background: #224e96;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: #fff;
  .title {
    .title-pinyin,
    .title-hanzi {
      text-align: center;
    }
  }
}
.darg-to-dress-game-container {
  background: #fff;
  border-radius: 48px;
  position: relative;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      position: relative;
      //   width: fit-content;
      .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
    }
    .drop-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .drag-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        cursor: pointer;
        width: 90%;
        -webkit-user-drag: inherit;
      }
      .option-item {
        position: absolute;
        -webkit-user-drag: auto;
        z-index: 99;
      }
      .notShow {
        opacity: 0;
      }
      .dispear {
        opacity: 0;
      }
    }
  }
  .lesson-32 {
    .drag-area {
      .option-1 {
        width: 18%;
        height: 25%;
        top: 40%;
        left: 7%;
      }
      .option-2 {
        width: 15%;
        height: 26%;
        top: 36%;
        left: 76%;
      }
      .option-3 {
        width: 12%;
        height: 14%;
        top: 13%;
        left: 85%;
      }
      .option-4 {
        width: 12%;
        height: 12%;
        top: 14%;
        left: 68%;
      }
    }
  }
  .lesson-32 {
    .drop-area {
      // background: #000;
      display: block;
      position: absolute;
      width: 16%;
      height: 31%;
      top: 38%;
      left: 41%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
