<template>
  <div class="game-container">
    <DragToShowSentenceGame
      :bgImgList="bgImgList"
      :titleInfo="titleInfo"
      :optionImgList="optionImgList"
      :lessonNO="32"
      @showDragImg="showDragImg"
    />
  </div>
</template>

<script>
import DragToShowSentenceGame from "@/components/Course/GamePage/DragToShowSentenceGame";
export default {
  data() {
    return {
      titleInfo:{
        pinyin:"Zhè shì shéi de?",
        hanzi:"这是谁的？"
      },
      bgImgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/1-dog-background.svg"),
          bgImgDone: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/1-dog-background-done.svg"),          
          id: 1,
          isDone:false,
          index:0,
          hanzi:"这是狗的。",
          pinyin:"Zhè shì gǒu de."
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/2-cat-background.svg"),
          bgImgDone: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/2-cat-background-done.svg"),
          isDone:false,
          id: 2,
          index:1,
          hanzi:"这是猫的。",
          pinyin:"Zhè shì māo de."
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/3-bird-background.svg"),
          bgImgDone: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/3-bird-background-done.svg"),
          isDone:false,
          id: 3,
          index:2,
          hanzi:"这是鸟的。",
          pinyin:"Zhè shì niǎo de."
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/4-cat-background.svg"),
          bgImgDone: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/4-cat-background-done.svg"),
          isDone:false,
          id: 2,
          index:3,
          hanzi:"这是猫的。",
          pinyin:"Zhè shì māo de."
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/5-fish-background.svg"),
          bgImgDone: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/5-fish-background-done.svg"),
          isDone:false,
          id: 4,
          index:4,
          hanzi:"这是鱼的。",
          pinyin:"Zhè shì yú de."
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/6-dog-background.svg"),
          bgImgDone: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/6-dog-background-done.svg"),
          isDone:false,
          id: 1,
          index:5,
          hanzi:"这是狗的。",
          pinyin:"Zhè shì gǒu de."
        },
      ],
      optionImgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/dog.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/fish.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/bird.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-32/cat.svg"),
        },
      ],
    };
  },
  components: {
    DragToShowSentenceGame,
  },
  methods:{
    showDragImg(index) {
      this.bgImgList[index].isDone = true
    }
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











